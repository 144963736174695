import React, { useContext, useEffect } from 'react';
import ScrollableAnchor, { goToAnchor } from 'react-scrollable-anchor';
import { Grid, Row, Col } from 'react-flexbox-grid';
import BrandCTA from 'components/BrandCta';
import SourceEmitter from 'libs/emitter';
import { appContext } from 'providers/appProvider';
import { Layout, Seo } from 'components';
import ContentBlock from 'components/Content/ContentBlock';
import ColorBlock from 'components/Content/ColorBlock';
import AlertBox from 'components/Content/AlertBox';

import './how.scss';

import step1 from '../../assets/images/nasal-spray-step1.png';
import step2 from '../../assets/images/nasal-spray-step2.png';
import step3 from '../../assets/images/nasal-spray-step3.png';
import howHeroMobile from '../../assets/images/how-to-use-hero-mobile.png';

const HowPage = () => {
  let Subscription = null;
  const { appConfigs } = useContext(appContext);

  const indication = appConfigs?.siteOptions?.INDICATIONS?.patient;
  // const metaData = appConfigs?.metaData?.patient;

  const brandNames = {
    NASCOBAL: appConfigs?.metaData?.brandNames?.nascobal,
    BARIACTIV: appConfigs?.metaData?.brandNames?.bariactiv,
    NUTRITION: appConfigs?.metaData?.brandNames?.nutrition,
  };

  const triggerHashChange = () => {
    const currentHash = window?.location?.hash?.substring(
      1,
      window?.location?.hash?.length,
    );
    SourceEmitter.emit('HashChangedEventTrigger', currentHash);
  };

  useEffect(() => {
    window.addEventListener('hashchange', triggerHashChange, false);
    // Triggered from Logo Component
    Subscription = SourceEmitter.addListener('gotoTopTriggerEvent', data => {
      goToAnchor('top');
    });
    return () => {
      window.removeEventListener('hashchange', triggerHashChange, false);
      Subscription && Subscription.remove();
    };
  }, []);

  const pageTitle = 'NASCOBAL® | How To Use NASCOBAL® Nasal Spray';
  const pageDescription = 'Learn how to use NASCOBAL® Nasal Spray';

  const renderPage = () => {
    return (
      <div id="nascobal-patient-how">
        <ContentBlock>
          <Grid
            fluid
            style={{ paddingLeft: 0, paddingRight: 0, paddingTop: 0 }}
          >
            <Row>
              <Col xs={12}>
                <div id="hero" className="hide-in-small">
                  <h2 className="txt-uppercase">
                    HOW TO USE <br className="hide-only-mobile" />
                    <span className="yellow">
                      <span
                        className="brandname-nascobal"
                        dangerouslySetInnerHTML={{
                          __html: brandNames?.NASCOBAL,
                        }}
                      />{' '}
                      <br className="hide-only-mobile" />
                      NASAL SPRAY
                    </span>
                  </h2>
                </div>
                <div id="hero-mobile" className="show-in-small">
                  <img src={howHeroMobile} />
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <ColorBlock white>
                  <p className="sub-title">
                    Follow the steps below to see how simple it is to use
                    NASCOBAL
                    <sup className="brand-reg-mark">&reg;</sup>
                  </p>
                </ColorBlock>
              </Col>
            </Row>
            <ColorBlock blue id="colorblock-nasal-spray">
              <Row>
                <Col xs={12} md={4}>
                  <img
                    src={step1}
                    style={{ marginBottom: 0 }}
                    alt="nasal-spray-step1"
                  />
                  <ul>
                    <li>
                      The unit contains only 1 spray:
                      <br />
                      <strong>DO NOT prime before use</strong>
                    </li>
                    <li>Blow nose gently to clear both nostrils</li>
                    <li>
                      Hold the unit with your thumb, supporting it at the
                      bottom, and your index and middle fingers on either side
                      of the nozzle
                    </li>
                  </ul>
                </Col>
                <Col xs={12} md={4}>
                  <img
                    src={step2}
                    style={{ marginBottom: 0 }}
                    alt="nasal-spray-step2"
                  />
                  <ul>
                    <li>
                      Gently close 1 nostril with your other index finger.
                      Insert the nozzle into open nostril approximately ½ inch,
                      or as far as feels comfortable, and tilt your head
                      slightly forward. Do not press the plunger yet{' '}
                    </li>
                    <li>
                      Breathe in gently through your nose, close your mouth, and
                      at the same time, press the plunger firmly with your thumb
                    </li>
                  </ul>
                </Col>
                <Col xs={12} md={4}>
                  <img
                    src={step3}
                    style={{ marginBottom: 0 }}
                    alt="nasal-spray-step3"
                  />
                  <ul>
                    <li>
                      Remove the nozzle from your nostril. At the same time,
                      keep your head level for 10 to 20 seconds while gently
                      breathing in through your nose and breathing out through
                      your mouth
                    </li>
                  </ul>
                </Col>
              </Row>
              <AlertBox>
                Do not drink hot liquids or eat hot foods at least 1 hour before
                and 1 hour after taking{' '}
                <span
                  className="brandname-nascobal"
                  dangerouslySetInnerHTML={{
                    __html: brandNames.NASCOBAL,
                  }}
                />{' '}
                Nasal Spray.
                <p className="text-blue">
                  <strong>*Dose adjustments may be required.</strong>
                </p>
              </AlertBox>
            </ColorBlock>
            <Row>
              <Col xs={12} md={6}>
                <BrandCTA
                  href="/vitaminb12/about"
                  LinkCaption="DISCOVER CONVENIENT DOSING"
                >
                  DISCOVER CONVENIENT DOSING
                </BrandCTA>
              </Col>
              <Col xs={12} md={6}>
                <BrandCTA
                  href="/vitaminb12/resources"
                  LinkCaption="DOWNLOAD PATIENT RESOURCES"
                >
                  DOWNLOAD PATIENT RESOURCES
                </BrandCTA>
              </Col>
            </Row>
          </Grid>
        </ContentBlock>
        <ScrollableAnchor id="isi_anchor">
          <div />
        </ScrollableAnchor>
      </div>
    );
  };

  return (
    <Layout indication={indication} className="patient-how">
      <Seo pageTitle={pageTitle} pageDescription={pageDescription} />
      <div className="body-content-container">
        <div className="body-content gutter-all">{renderPage()}</div>
      </div>
    </Layout>
  );
};

export default HowPage;
